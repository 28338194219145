import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import { useEffect, useState } from 'react';
import { ISiteMngData } from '../types';
import { MRT_TableInstance } from 'material-react-table';

interface IProps {
  data: ISiteMngData[];
  setData: (newData: ISiteMngData[]) => void;
  table: MRT_TableInstance<ISiteMngData>;
  setEditedUids: (any: any) => void;
}
const OptInOption: React.FC<IProps> = (props) => {
  const [checked, setChecked] = useState(false);
  const [showOptInLabel, setShowOptInLabel] = useState(true);

  useEffect(() => {
    const selectedRows = props.table.getSelectedRowModel().rows;

    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      const allOptedIn = selectedRows?.every((item) => item?.original?.optedIn);
      setShowOptInLabel(allOptedIn ? false : true);
    } else {
      const allOptedIn = props?.data?.every((item) => item?.optedIn);
      setShowOptInLabel(allOptedIn ? false : true);
    }
  }, [props]);

  return (
    <Box>
      <FormControlLabel
        label={showOptInLabel ? 'Opt All In' : 'Opt All Out'}
        control={
          <Checkbox
            checked={checked}
            onChange={(event) => {
              const value = event.target.checked;
              const selectedRows = props.table.getSelectedRowModel().rows;
              if (Array.isArray(selectedRows) && selectedRows.length > 0) {
                const confirmPopup = confirm(`This will modify ${selectedRows.length} rows`);
                if (confirmPopup) {
                  const ids = selectedRows.map((item) => item.id);
                  // Create a hash map of IDs for faster lookup
                  const idMap: Record<string, boolean> = {};
                  ids.forEach((id) => {
                    idMap[id] = true;
                  });
                  // Update props.data if its id matches an item inside it
                  const newData = props.data.map((item) => {
                    if (idMap[item.uid]) {
                      props.setEditedUids((arr: any) => [...arr, item.uid]);
                      return { ...item, optedIn: showOptInLabel };
                    }
                    return item;
                  });
                  props.setData(newData);
                  setChecked(value);
                }
              }
              if (selectedRows.length < 1) {
                alert('Select atleast one row to do bulk operations');
              }
            }}
          />
        }
      />
    </Box>
  );
};

export default OptInOption;
