export interface ISiteMngData {
  protocolName: string;
  ctmsRowId: string;
  source: string;
  language?: string;
  optedIn?: boolean;
  status?: string;
  siteName?: string;
  address?: string;
  country?: string;
  zip?: string;
  latitude?: string;
  longitude?: string;
  siteCode?: string;
  city?: string;
  contactPhone?: string;
  piName?: string;
  contactEmail?: string;
  state?: string;
  accountNumber?: string;
  facilityType?: string;
  radiusInMiles?: number;
  priority?: number;
  threshold?: number;
  siteDirectedSchedule?: boolean;
  warmTransferNumber?: string;
  econsentUrl?: string;
  openingHour?: number;
  closingHour?: number;
  isDefaultVirtualSite?: boolean;
  allowEmails?: boolean;
  allowSmses?: boolean;
  emailCras?: string;
  studyStaffEmails?: string;
  dataVisualisationsApproved?: boolean;
  subRows: ISiteMngData[];
  uid: string; // UID is added via helper function. Will not be present in api response
  smaRowId: string;
  isSubRow?: boolean;
  eprId?: string | null;
}

export interface IStudyItem {
  studyItemId: string;
  studyUrl: string;
  studyName: string;
}

export interface IField {
  fieldValue: string;
  fieldLabel: string;
  fieldKey: string;
  itemId: string;
  isEditable: boolean;
}

export interface ISiteStatus {
  id: string;
  name: string;
  value: string;
  key: string;
  optedIn: boolean;
}

export interface ISmaLanguage {
  iso: string;
  languageName: string;
  languageId: string;
  regionalIsoCode: string;
}

export interface IInitialFilterRes {
  itemName: string;
  itemPath: string;
  itemId: string;
  eprStudyList: IStudyItem[];
  taStudyList: IStudyItem[];
  fields: IField[];
  eprFields: IField[];
  siteStatuses: ISiteStatus[];
  smaLanguageList: ISmaLanguage[];
  optedIn: boolean;
  radius: number;
  priority: number;
  threshold: number;
  warmTransferNumber: string;
  optInDateTime: string;
  isDefaultVirtualSite: boolean;
  isHybridSite: boolean;
  kiElementsEnabled: boolean;
  siteDirectedSchedule: boolean;
}

export interface IProtocoItem {
  itemID: string;
  itemName: string;
}
export interface IProtocolListRes {
  protocol?: IProtocoItem[];
}
export const EPR_TYPE = { Global: 'global' };
