import { post } from '../../lib/utils/axiosFetcher'; // dap.api
import * as axiosSiteApi from '../../lib/utils/axiosSiteApi';
// import mockData, { smaGlobalSetting, mockProtocolRes } from './mockData';
import { IInitialFilterRes, IProtocolListRes } from './types';
import { ISiteMngData } from './types';
import axios, { AxiosResponse } from 'axios';

export enum Roles {
  AUTHOR = 'SMAAuthor',
  APPROVER = 'SMAApprover',
  ADMIN = 'Admin',
}

export const formatSitesForTranslation = (
  sites: ISiteMngData[],
  toLang: string,
  fieldOptions: any
): any => {
  const getTranslateableFields = (fieldOptions: any) => {
    const translateableFields: string[] = [];
    for (const fields in fieldOptions) {
      if (fieldOptions[fields]?.isTranslatable) {
        // TODO change to field value for translation from isEditable
        translateableFields.push(fieldOptions[fields]?.fieldKey);
      }
    }
    return translateableFields;
  };

  const sitesDataRequest: any = [];
  sites.map((site: ISiteMngData) => {
    const translateableFields = getTranslateableFields(fieldOptions);
    const formattedObj: any = {};

    for (const [key, value] of Object.entries(site)) {
      if (translateableFields.includes(key)) {
        formattedObj[key] = value;
      }
    }
    formattedObj.id = site.uid;
    sitesDataRequest.push(formattedObj);
  });

  const formattedSites = {
    from: 'en', //TODO cross verify with deependra
    to: toLang,
    sitesDataRequest: sitesDataRequest,
  };

  return formattedSites;
};

export const fetchSites = async (
  protocolName: string,
  language: string,
  siteDetailTarget: string
): Promise<any[]> => {
  try {
    const reqBody = {
      protocolName,
      language,
      siteDetailTarget,
    };
    if (!reqBody.protocolName) {
      alert('Protocol is required');
    }
    if (!reqBody.language) {
      alert('Language is required');
    }
    // return mockData;
    const response = await post('/SMA/FetchSMASitesDataFromDb', reqBody);
    const responseData = response.data;
    if (Array.isArray(responseData) && responseData.length > 0) {
      return responseData;
    } else {
      return [];
    }
  } catch (_error) {
    return [];
  }
};

export const saveSites = async (sites: ISiteMngData[]): Promise<boolean> => {
  try {
    const reqBody = sites;
    const response = await post('/SMA/SaveSitesDataInDbFromSma', reqBody);
    const responseData = response.data;
    if (responseData) {
      return true;
    } else {
      return false;
    }
  } catch (_error) {
    return false;
  }
};

export const translateSites = async (translationData: any): Promise<any> => {
  try {
    const reqBody = translationData;
    const response = await post('/SMA/TranslationSMASitesData', reqBody);
    const responseData = response.data;
    return responseData;
  } catch (_error) {
    return false;
  }
};

export const getSMAGlobalSetting = async (): Promise<IInitialFilterRes> => {
  try {
    // return smaGlobalSetting;
    const response = await axiosSiteApi.get('/SMA/GetSMAGlobalSetting');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const isSiteIdAlreadyAvailable = async (id: any): Promise<any> => {
  try {
    const response = await post(`/SMA/CheckExistingSitecode?siteCode=${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProtocolList = async (
  eprId: string,
  lang: string,
  isGlobal: boolean
): Promise<IProtocolListRes> => {
  try {
    // return mockProtocolRes;
    if (isGlobal) {
      const globalId = '{01442196-D652-4F46-997F-8A741C1D5FDC}';
      const response = await axiosSiteApi.get(
        `/SMA/GetGlobalProtocolItems?globalItemId=${globalId}&language=${lang}`
      );
      return { protocol: response.data };
    }
    const response = await axiosSiteApi.get(
      `/SMA/GetSMASiteSetting?eprId=${eprId}&language=${lang}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Adds uid to every site both parent and subRows
 *  uid is just a combination of smaRowId + _ + source
 */
function addUIDToSitesData(data: ISiteMngData[]) {
  const newData = data.map((item) => {
    const uid = `${item.smaRowId}_${item.source}`;
    return { ...item, uid };
  });

  newData.forEach((item) => {
    if (item.subRows && Array.isArray(item.subRows) && item.subRows.length > 0) {
      item.subRows.forEach((subItem) => {
        const subUID = `${subItem.smaRowId}_${subItem.source}`;
        subItem.uid = subUID;
      });
    }
  });

  return newData;
}

/**
 * Sorts the fetched sites data based on the orderMap so that staging is always showed on the top
 */
function customSort(arr: ISiteMngData[]) {
  const orderMap: Record<string, number> = {
    staging: 0,
    source: 1,
    published: 2,
  };

  arr.sort((a, b) => {
    const sourceA = a.source;
    const sourceB = b.source;

    const orderA = orderMap[sourceA] !== undefined ? orderMap[sourceA] : Infinity;
    const orderB = orderMap[sourceB] !== undefined ? orderMap[sourceB] : Infinity;

    return orderA - orderB;
  });

  return arr;
}

export const getSites = async (
  protocolName: string,
  language: string,
  siteDetailTarget: string
): Promise<any[]> => {
  const sitesFromDB = await fetchSites(protocolName, language, siteDetailTarget);
  const sitesWithUid = addUIDToSitesData(sitesFromDB);
  const sitessorted = customSort(sitesWithUid);
  // sitessorted.forEach((item) => {
  //   item.approve = false;
  // });
  return sitessorted;
};

export async function verifyJwtToken(jwtToken: string): Promise<any> {
  try {
    const apiUrl = '/api/verify-sma-token';
    const response: AxiosResponse<any> = await axios.post(apiUrl, {
      token: jwtToken,
    });
    return response.data;
  } catch (error) {
    return { isValid: false, payload: {} };
  }
}

export const saveCTMSSitesToRawDb = async (
  protocolName: string,
  globalSettings: any = {},
  statusList: string // Comma separated value of all site status options
) => {
  const {
    optedIn = false,
    radius = 0,
    priority = 0,
    threshold = 0,
    warmTransferNumber = '',
    optInDateTime = new Date().toISOString(),
    isDefaultVirtualSite = false,
    isHybridSite = false,
    kiElementsEnabled = false,
    siteDirectedSchedule = false,
    language = 'en',
  } = globalSettings;
  const reqBody = {
    protocols: protocolName,
    siteStatuses: statusList,
    updatedBy: 'CTMS',
    config: {
      isDefaultVirtualSite,
      isHybridSite,
      kiElementsEnabled,
      optedIn,
      optInDateTime,
      priority,
      radius,
      siteDirectedSchedule,
      threshold,
      warmTransferNumber,
      language,
    },
  };

  try {
    await post('/CTMS/SaveCTMSSitesToRawDb', reqBody);
  } catch (error) {
    throw error;
  }
};

export const getCountryStatesList = async (lang: string): Promise<IProtocolListRes> => {
  try {
    const countryItemId = '{0BAEDE80-BEE8-493D-B548-B60A6935A31C}';
    const response = await axiosSiteApi.get(
      `/SMA/GetGlobalStateData?globalCountryItemId=${countryItemId}&language=${lang}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
