import axios from 'axios';
import { logger } from '../../logger';

const get = async (url: string): Promise<any> => {
  try {
    const response = await axios.get('/api/site-api', { params: { url } });
    logger.info(`${process.env.NEXT_PUBLIC_DAP_SITE_API_URL}${url}`, {
      response: response?.data,
      payload: {},
    });
    return response;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || '';
    logger.error(`${process.env.NEXT_PUBLIC_DAP_SITE_API_URL}${url}`, {
      error: errorMessage,
      payload: {},
    });
    throw error;
  }
};

const post = async (url: string, payload?: any): Promise<any> => {
  try {
    const response = await axios.post('/api/site-api', {
      url,
      data: payload,
    });
    logger.info(`${process.env.NEXT_PUBLIC_DAP_SITE_API_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || '';
    logger.error(`${process.env.NEXT_PUBLIC_DAP_SITE_API_URL}${url}`, {
      error: errorMessage,
      payload: payload,
    });
    throw error;
  }
};

const put = async (url: string, payload: any): Promise<any> => {
  try {
    const response = await axios.put('/api/site-api', {
      url,
      data: payload,
    });
    logger.info(`${process.env.NEXT_PUBLIC_DAP_SITE_API_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || '';
    logger.error(`${process.env.NEXT_PUBLIC_DAP_SITE_API_URL}${url}`, {
      error: errorMessage,
      payload: payload,
    });
    throw error;
  }
};

export { get, post, put };
