import { useSelector } from 'react-redux';
import { EPR_TYPE } from './types';

const Legend = () => {
  const selectedFilters = useSelector((state: any) => state?.sma?.selectedFilters);

  const legendStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const squareStyle = {
    width: '20px',
    height: '20px',
    marginRight: '5px',
    border: '1px solid #000',
  };

  return (
    <div style={legendStyle}>
      <div style={{ ...squareStyle, backgroundColor: '#ffffff' }}></div>
      {'Staging'} &nbsp;
      {(!selectedFilters.EPR || selectedFilters.EPR === EPR_TYPE.Global) && (
        <>
          <div style={{ ...squareStyle, backgroundColor: '#FFE985' }}></div>
          {'Source'} &nbsp;
        </>
      )}
      <div style={{ ...squareStyle, backgroundColor: '#79B983' }}></div>
      {'Published'}
    </div>
  );
};

export default Legend;
