import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getProtocolList, getSites, saveCTMSSitesToRawDb, getCountryStatesList } from '../helpers';
import { ISiteMngData } from '../types';
import { IProtocoItem, ISmaLanguage, IStudyItem } from '../types';
import { useDispatch } from 'react-redux';
import {
  updateSelectedFilters,
  updateSelectedProtocol,
  updateProtocolItemId,
  setCountryStatesOptions,
} from '../../../features/smaSlice';
import { useSelector } from 'react-redux';

interface IProps {
  data: ISiteMngData[];
  setData: (newData: ISiteMngData[]) => void;
  eprOptions: IStudyItem[];
  langOptions: ISmaLanguage[];
  setIsLoading: (value: boolean) => void;
  setSelectedEprName: React.Dispatch<React.SetStateAction<string>>;
  setCloneData: (newData: ISiteMngData[]) => void;
}

function getStudyName(studyItemId: string, studyArray: any): string {
  if (studyItemId === 'global') {
    return studyItemId;
  }
  for (const study of studyArray) {
    if (study.studyItemId === studyItemId) {
      return study.studyName;
    }
  }
  return '';
}

const isShowRefreshSites = (selectedEPR: string, selectedProtocol: string) => {
  if (selectedEPR === 'global' && Boolean(selectedProtocol)) {
    return true;
  } else {
    return false;
  }
};
const isShowGetSites = (selectedEPR: string, selectedLang: string, selectedProtocol: string) => {
  return Boolean(selectedEPR) && Boolean(selectedLang) && Boolean(selectedProtocol);
};

const Filters: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState({
    language: '',
    EPR: '',
  });
  const [protocolList, setProtocolList] = useState<IProtocoItem[]>([]);
  const [selectedProtocol, setSelectedProtocol] = useState('');
  const refreshSiteConfig = useSelector((state: any) => state?.sma?.refreshSiteConfig);
  const siteStatusOptions = useSelector((state: any) => state?.sma?.siteStatusOptions);

  const handleSelectChange = (event: any) => {
    try {
      setSelectedProtocol('');
      props.setData([]);
      props.setCloneData([]);
      const name = event?.target?.name;
      const value = event?.target?.value;
      setSelectedValues({
        ...selectedValues,
        [name]: value,
      });
      dispatch(updateSelectedProtocol(''));
    } catch (_error) {}
  };
  const onProtocolChange = (event: any) => {
    try {
      props.setData([]);
      props.setCloneData([]);
      const value = event?.target?.value;
      setSelectedProtocol(value);
      const itemId = protocolList.find((item) => item.itemName === value)?.itemID;
      dispatch(updateSelectedProtocol(value));
      dispatch(updateProtocolItemId(itemId));
    } catch (_error) {}
  };
  const onGetSites = async () => {
    props.setIsLoading(true);
    const sitesData = await getSites(selectedProtocol, selectedValues.language, selectedValues.EPR);
    props.setIsLoading(false);
    props.setData(sitesData);
    props.setCloneData(sitesData);
  };
  const isProtocolListNonEmpty = Array.isArray(protocolList) && protocolList.length;

  const onRefreshSiteDetails = async () => {
    try {
      const confirmPopup = confirm(
        'Refresh Site Details gets the latest sites from CTMS for the selected protocol. This may take some time. Do you want to continue?'
      );
      if (confirmPopup) {
        props.setIsLoading(true);
        let statusList = '';
        if (Array.isArray(siteStatusOptions)) {
          statusList = siteStatusOptions.toString();
        }
        await saveCTMSSitesToRawDb(selectedProtocol, refreshSiteConfig, statusList);
        props.setIsLoading(false);
        alert('Refresh CTMS Site Data Completed');
        onGetSites();
      } else {
        return;
      }
    } catch (error) {
      props.setIsLoading(false);
      alert('No sites found in CTMS for the selected Protocol and status.');
    }
  };

  useEffect(() => {
    dispatch(updateSelectedFilters(selectedValues));
    const populateProtocolOptions = async (eprId: string, lang: string) => {
      try {
        props.setIsLoading(true);
        const isGlobal = eprId === 'global';
        const protocolOptions = await getProtocolList(eprId, lang, isGlobal);
        if (protocolOptions && Array.isArray(protocolOptions.protocol)) {
          setProtocolList(protocolOptions.protocol);
        }
        props.setIsLoading(false);
      } catch (_err) {
        props.setIsLoading(false);
      }
    };
    if (selectedValues.EPR && selectedValues.language) {
      // Fetch Protocol once EPR and Lang is selected
      populateProtocolOptions(selectedValues.EPR, selectedValues.language);
    }
  }, [selectedValues]);

  useEffect(() => {
    props.setSelectedEprName(getStudyName(selectedValues.EPR, props.eprOptions));
  }, [selectedValues.EPR]);

  useEffect(() => {
    const getSetCountryStates = async (lang: string) => {
      try {
        props.setIsLoading(true);
        const countryStatesList = await getCountryStatesList(lang);
        const countryStateNames: { name: string; value: string }[] = [];
        if (Array.isArray(countryStatesList)) {
          countryStatesList.forEach(
            (i: { stateValue: string | null; stateItemID: string | null }) => {
              if (i.stateValue && i.stateItemID) {
                countryStateNames.push({ name: i.stateValue, value: i.stateItemID });
              }
            }
          );
        }
        dispatch(setCountryStatesOptions(countryStateNames));
        props.setIsLoading(false);
      } catch (error) {
        props.setIsLoading(false);
      }
    };
    if (selectedValues.language) {
      getSetCountryStates(selectedValues.language);
    }
  }, [selectedValues.language]);

  return (
    <Box
      sx={{
        minWidth: '100%',
        display: 'flex',
        columnGap: '1rem',
        justifyContent: 'space-evenly',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="select-EPR-label">EPR</InputLabel>
        <Select
          name="EPR"
          labelId="select-EPR-label"
          id="EPR-selector"
          value={selectedValues.EPR}
          label="EPR"
          onChange={handleSelectChange}
        >
          <MenuItem value={'global'}>Global</MenuItem>
          {props.eprOptions.map((item, index) => (
            <MenuItem key={index} value={item.studyItemId}>
              {item.studyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="select-language-label">Language</InputLabel>
        <Select
          name="language"
          labelId="select-language-label"
          id="language-selector"
          value={selectedValues.language}
          label="Language"
          onChange={handleSelectChange}
        >
          {props.langOptions.map((item, index) => (
            <MenuItem key={index} value={item.languageName}>
              {item.languageName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="select-protocol-label">Protocol</InputLabel>
        <Select
          disabled={!isProtocolListNonEmpty}
          name="protocol"
          labelId="select-protocol-label"
          id="protocol-selector"
          value={selectedProtocol}
          label="Protocol"
          onChange={onProtocolChange}
        >
          {isProtocolListNonEmpty &&
            protocolList.map((item, index) => (
              <MenuItem key={index} value={item.itemName}>
                {item.itemName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Box>
        <Button
          variant={'contained'}
          sx={{
            minWidth: '150px',
            maxWidth: '150px',
            minHeight: '100%',
          }}
          disabled={!isShowGetSites(selectedValues.EPR, selectedValues.language, selectedProtocol)}
          onClick={onGetSites}
        >
          Get Sites
        </Button>
      </Box>
      <Box>
        <Button
          variant={'contained'}
          sx={{
            minWidth: '150px',
            maxWidth: '150px',
            minHeight: '100%',
          }}
          disabled={!isShowRefreshSites(selectedValues.EPR, selectedProtocol)}
          onClick={onRefreshSiteDetails}
        >
          Refresh CTMS Site Data
        </Button>
      </Box>
    </Box>
  );
};
export default Filters;
