import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface IProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  handleTranslateClick: () => void;
}

const TranslateModal: React.FC<IProps> = (props) => {
  const open = props.isOpen;
  const setOpen = props.setOpen;

  const handleClose = () => {
    setOpen(false);
  };
  const onTranslateClick = () => {
    props.handleTranslateClick();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle variant={'h5'}>Translate</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <Box
            sx={{
              paddingTop: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              columnGap: '1rem',
            }}
          >
            <Typography sx={{ color: '#444444 !important' }}>
              The selected rows will be translated and new records will be created with translated
              data.
            </Typography>
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onTranslateClick}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
};
export default TranslateModal;
